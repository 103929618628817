<template>
  <v-card>
    <v-navigation-drawer
      color="secondary"
      :order="-1"
      permanent
    >
      <v-list-item class="pt-10 px-5">
        <logo />
      </v-list-item>

      <v-list class="px-5 pt-10">
        <v-list-item
          v-for="sidebarRoute in routes"
          :key="sidebarRoute.name"
          :active="routeActive(sidebarRoute)"
          :title="sidebarRoute.title"
          ripple
          rounded="lg"
          @click="navigateTo(sidebarRoute.name)"
        >
          <template #prepend="{isActive}">
            <v-badge
              :model-value="sidebarRoute.badge ?? false"
              :color="sidebarRoute.badgeColor ?? 'primary'"
              location="top start"
              dot
            >
              <v-icon :color="isActive ? 'primary' : 'base400'" :icon="isActive ? sidebarRoute.selectedIcon : sidebarRoute.icon" />
            </v-badge>
          </template>
        </v-list-item>
      </v-list>

      <template #append>
        <div class="pa-5">
          <v-list-item
            v-ripple
            class="pa-5"
            prepend-icon="mdi-logout"
            :title="$t('logout')"
            @click="logout"
          />
        </div>
      </template>
    </v-navigation-drawer>
  </v-card>
</template>

<script setup>
import Logo from '@/components/PortalLogo';
import { useUserStore } from '@/store/user';
import router from '@/router';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useNotificationStore } from '@/store/notifications';
import { computed } from 'vue';

const route = useRoute();
const i18n = useI18n();

const userStore = useUserStore();
const { logoutUser } = userStore;

const { hasNewNotifications } = storeToRefs(useNotificationStore());

const routes = computed(() => [
  {
    name: 'installations',
    icon: '$installations',
    selectedIcon: '$installations_selected_sidebar',
    title: i18n.t('bottomNav.installations'),
  },
  {
    name: 'loyalty',
    icon: 'mdi-gift-outline',
    selectedIcon: 'mdi-gift-outline',
    title: i18n.t('bottomNav.loyalty'),
  },
  {
    name: 'support',
    icon: '$support',
    selectedIcon: '$support_selected',
    title: i18n.t('bottomNav.support'),
  },
  {
    name: 'account',
    icon: '$account',
    selectedIcon: '$account_selected',
    title: i18n.t('bottomNav.account'),
  },
  {
    name: 'notifications',
    icon: 'mdi-bell-outline',
    selectedIcon: 'mdi-bell-outline',
    title: i18n.t('bottomNav.notifications'),
    badge: hasNewNotifications.value,
  },
]);

function navigateTo(path) {
  router.push({ name: `${path}` });
}

function routeActive(sidebarRoute) {
  return route.matched.some((matched) => matched.name === sidebarRoute.name || matched.children.some((matchedChild) => matchedChild.name === sidebarRoute.name));
}

function logout() {
  logoutUser();
}

</script>

<style scoped>

.noOpacity {
    opacity: 100 !important;
    color: black;
}

</style>
