/**
 * router/index.ts
 *
 * Automatic routes for `./src/pages/*.vue`
 */

import { updateRouterHistory } from './routerNavGuard';

// Composables
import { createRouter, createWebHistory } from 'vue-router/auto';
import { hasSubdomain } from '@/helpers/util';
import { useUserStore } from '@/store/user';

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    else {
      if (to.hash) {
        return {
          el: to.hash,
          behavior: 'smooth',
        };
      }
      return { top: 0 };
    }
  },
});

router.beforeEach(async (to, from, next) => {
  // check if user data is instantiated. If yes user must be logged in, else check if it can be fetched. this also checks if keycloak is still set.
  const userStore = useUserStore();

  if (userStore.user === undefined) {
    await userStore.updateUser();

    if (to.meta.requiresAuth && !userStore.userLoggedIn) {
      next({ path: '/login' });
      return;
    }
  }

  if (!hasSubdomain() && to.path !== '/') {
    next({ path: '/' });
  }
  else {
    updateRouterHistory(to, from, next);
  }
});

export default router;
